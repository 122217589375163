import { flow, types } from "mobx-state-tree";

const Reserv = types.model('Reserv', {
    people: types.number,
    date: types.string,
    departure_time: types.string,
    cost: types.number,
    tour: types.string,
    additional_people: types.number,
    name: types.string,
    last_name: types.string,
    email: types.string,
    phone: types.string,
    additional_comments: types.string,
    total_cost: types.number
}).actions((self)=>{
    const SetPeople = flow(function *(people){
        self.people = people;
    });

    const SetDate = flow(function *(date){
        self.date = date;
    });
    
    const SetTour = flow(function *(tour){
        self.tour = tour;
    });

    const SetCost = flow(function *(cost){
        self.cost = cost;
    });

    const SetDepartureTime = flow(function *(time){
        self.departure_time = time;
    });

    const SetAdditionalPeople = flow(function *(people){
        self.additional_people = people;
    });

    const SetName = flow(function *(name){
        self.name = name;
    });

    const SetLastName = flow(function *(lastName){
        self.last_name = lastName;
    });

    const SetEmail = flow(function *(email){
        self.email = email;
    });

    const SetPhone = flow(function *(phone){
        self.phone = phone;
    });

    const SetAdditionalComments = flow(function *(comments){
        self.additional_comments = comments;
    });

    const SetTotalCost = flow(function *(total){
        self.total_cost = total;
    });

    return {
        SetPeople,
        SetDate,
        SetTour,
        SetCost,
        SetDepartureTime,
        SetAdditionalPeople,
        SetName,
        SetLastName,
        SetEmail,
        SetPhone,
        SetAdditionalComments,
        SetTotalCost
    }
}).create({
    people: 0,
    date: '',
    departure_time: '',
    cost: 0,
    tour: '',
    additional_people: 0,
    name: '',
    last_name: '',
    email: '',
    phone: '',
    additional_comments: '',
    total_cost: 0
});

export default Reserv;