import styled from "styled-components";
import { BreakPoints } from "../fixtures/BreakPoints";
import { Colors } from "../theme/Colors";

export const Logo = styled.img`
    height: ${(props) => props.height};
    width: ${(props) => props.width};
`;

export const HeadingWhiteHeader = styled.h1`
    font-size: 100px;
    color: ${Colors.white};

    @media (max-width: ${BreakPoints.lg}){
        font-size: 50px;
    }
`;

export const Heading = styled.h2`
    font-size: 70px;
    color: ${Colors.black};
    font-weight: lighter;
    text-transform: ${(props) => props.uppercase && 'uppercase'};

    @media (max-width: ${BreakPoints.lg}){
        font-size: 44px;
    }
`;

export const HeadingWhite = styled.h2`
    font-size: 70px;
    color: ${Colors.white};
`;

export const TitleDetailTourPost = styled.h1`
    font-size: 68px;
    color: ${Colors.black};
    pointer-events: none;

    @media (max-width: ${BreakPoints.lg}){
        font-size: 22px;
    }
`;

export const TitleHeader = styled.h2`
    font-size: 44px;
    color: ${Colors.black};
    pointer-events: none;

    @media (max-width: ${BreakPoints.lg}){
        font-size: 22px;
    }
`;

export const TitleWhiteHeader = styled.h2`
    font-size: 44px;
    color: ${Colors.white};

    @media (max-width: ${BreakPoints.lg}){
        font-size: 22px;
    }
`;

export const Title = styled.h2`
    font-size: 32px;
    color: ${Colors.black};
`;

export const TitleWhite = styled.h2`
    font-size: 32px;
    color: ${Colors.white};
`;

export const SubTitle = styled.h3`
    font-size: 22px;
    color: ${Colors.black};
`;

export const SmallSubTitle = styled.h4`
    font-size: 16px;
    color: ${Colors.black};
    text-transform: uppercase;
    font-weight: bold;
`;

export const SubTitleItalicGray = styled.h3`
    font-size: 22px;
    color: ${Colors.gray};
    font-style: italic;
`;

export const SubTitleWhite = styled.h3`
    font-size: 22px;
    color: ${Colors.white};
`;

export const SubTitleItalicWhite = styled.h3`
    font-size: 22px;
    color: ${Colors.white};
    font-style: italic;
`;

export const Text = styled.p`
    font-size: 16px;
    color: ${Colors.black};
    margin: 0;
    pointer-events: none;
`;

export const TextWhite = styled.p`
    font-size: 16px;
    color: ${Colors.white};
    margin: 0;
`;

export const Caption = styled.p`
    font-size: 13px;
    pointer-events: none;
`;  

export const CaptionWhite = styled.p`
    font-size: 13px;
    color: ${Colors.white};
    pointer-events: none;
`;  

export const TextPriceItalic = styled.span`
    font-size: 28px;
    color: ${Colors.main_color};
    font-style: italic;
    pointer-events: none;
`;

export const DividerSmall = styled.div`
    background: ${Colors.main_color};
    height: 5px;
    width: 100px;
    pointer-events: none;
`;

export const DividerMicro = styled.div`
    background: ${Colors.main_color};
    height: 3px;
    width: 50px;
`;

export const DividerMicroWhite = styled.div`
    background: ${Colors.white};
    height: 3px;
    width: 50px;
`;

export const Line = styled.div`
    background: ${Colors.gray_light};
    height: 1px;
`;

export const LineBlue = styled.div`
    background: ${Colors.main_color};
    height: 2px;
`;