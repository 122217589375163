import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Colors } from '../theme/Colors';
import {IoMenuSharp, IoClose} from 'react-icons/io5';
import {Logo} from '../components/StyledComponents';
import logo from '../images/logo.png';
import { content } from '../fixtures/Content';
import gsap from 'gsap/gsap-core';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import Ui from '../stores/Ui';
import {useHistory} from 'react-router-dom';
import { NavLink, Link } from 'react-router-dom';

gsap.registerPlugin(ScrollToPlugin);

const TopMenuMobile = ({location}) => {

    const {push} = useHistory();

    const openMenu = ()=> {
        gsap.to('#top-mobile-menu-light-box',{width: '100%', duration: 0.3});
        gsap.to('#top-mobile-menu-holder',{left: 0, delay: 0.2});
    }

    const closeMenu = ()=> {
        gsap.to('#top-mobile-menu-holder',{left: '-100%', duration: 0.3});
        gsap.to('#top-mobile-menu-light-box',{width: 0, delay: 0.2});
    }

    const Goto = (section)=> {
        switch (section) {
            case 'home':
                gsap.to(window, {scrollTo: 0});
                break;
            
            case 'about':
                closeMenu();
                if(location){
                    if(location.pathname !== "/"){
                        push('/')
                    };
                }
                gsap.to(window, {scrollTo: '#about-wrapper'});
                break;

            case 'services':
                closeMenu();
                if(location){
                    if(location.pathname !== "/"){
                        push('/')
                    };
                }
                gsap.to(window, {scrollTo: '#services-wrapper'});
                break;

            case 'blog':
                gsap.to(window, {scrollTo: Ui.offsetTopBlog});
                break;
        
            default:
                break;
        }
    };

    return (
        <TopMenuMobileWrapper className='d-lg-none'>
            <TopMenuMobileLightBox id='top-mobile-menu-light-box'>
                <TopMenuMobileHolder id='top-mobile-menu-holder'>
                    <Container>
                        <Row className='align-items-center mb-4'>
                            <Col xs='3'>
                                <IoClose onClick={closeMenu} color={Colors.gray} size={40} />    
                            </Col>
                            <Col xs='1'>
                                <Logo src={logo} width={48} height={46} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Menu className='menu'>
                                    <MenuItem>
                                        <Link onClick={closeMenu} to='/'>{content.top_menu.home}</Link>
                                    </MenuItem>
                                    <MenuItem>
                                        <Link onClick={()=>{Goto('about')}}>{content.top_menu.about_us}</Link>
                                    </MenuItem>
                                    <MenuItem>
                                        <Link onClick={()=>{Goto('services')}}>{content.top_menu.services}</Link>
                                    </MenuItem>
                                    <MenuItem>
                                        <Link to='/blog'>{content.top_menu.blog}</Link>
                                    </MenuItem>
                                </Menu>
                            </Col>
                        </Row>
                    </Container>
                </TopMenuMobileHolder>
            </TopMenuMobileLightBox>
            <Container>
                <Row className='align-items-center'>
                    <Col xs='2'>
                        <IoMenuSharp style={{cursor: 'pointer'}} onClick={openMenu} color={Colors.main_color} size={40} />
                    </Col>
                    <Col xs='1'>
                        <Logo src={logo} width={36} height={34} />
                    </Col>
                </Row>
            </Container>
        </TopMenuMobileWrapper>
    )
}

const TopMenuMobileLightBox = styled.div`
    background: rgba(0,0,0,0.7);
    width: 0;
    height: 100%;
    position: fixed;
    z-index: 9999;
`;

const TopMenuMobileWrapper = styled.div`
    background-color: ${Colors.white};
    padding: 8;
`;

const TopMenuMobileHolder = styled.div`
    background-color: ${Colors.white};
    height: 100%;
    width: 60%;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 10000;
    padding: 16px;
`;

const Menu = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    border-bottom: solid 1px ${Colors.gray_light};
`;

const MenuItem = styled.li`
    color: ${Colors.black};
    font-size: 16px;
    margin-bottom: 16px;

    a {
        color: ${Colors.black};
        text-decoration: none;   
    }

    a:visited{
        color: ${Colors.black};
    }

    a:hover{
        color: ${Colors.black};
        text-decoration: none;
    }
`;

export default TopMenuMobile
