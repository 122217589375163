import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { DividerMicro, TitleDetailTourPost, Text, TextWhite, Caption, SmallSubTitle, Line, LineBlue } from '../components/StyledComponents';
import TopMenu from '../components/TopMenu';
import { Colors } from '../theme/Colors';
import parse from 'html-react-parser';
import { content } from '../fixtures/Content';
import moment from 'moment';
import * as Icon from 'react-icons/io';
import swal from '@sweetalert/with-react';
import Reserv from '../stores/Reserv';
import InputDataInformation from '../components/InputDataInformation';
import TextAreaDataInformation from '../components/TextAreaDataInformation';
import { ValidateEmail, ValidateNumbers } from '../fixtures/helpers';
import { callApi } from '../fixtures/api';
import TopMenuMobile from '../components/TopMenuMobile';
import Loading from '../components/Loading';

const TourDetail = ({location}) => {

    const [loading, setLoading] = useState(false);
    const [tour] = useState(location.state.tour);
    const [people, setPeople] = useState(tour.minimum_people);
    const [date, setDate] = useState(moment().add(1, 'day').format('YYYY-MM-DD'));
    const [minDate] = useState(moment().add(1, 'day').format('YYYY-MM-DD'));
    const [time, setTime] = useState('');
    const [reservActive, setReservActive] = useState(false);
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [errorEmail, setEerrorEmail] = useState(true);
    const [phone, setPhone] = useState('');
    const [errorPhone, setEerrorPhone] = useState(true);
    const [additionalComments, setAdditionalComments] = useState('');

    const seDateTour = (e)=> {
        const date_selected = e.target.value;
        setDate(date_selected);
    }

    const setTimeTour = (e)=> {
        const time_selected = e.target.value;
        setTime(time_selected);
    }

    const GoResumeReserv = async()=> {
        if(!time){
            swal({
                text: content.alert_messages.select_departure_time,
                icon: "warning" 
            });
            return;
        }
        await Reserv.SetCost(tour.price);
        await Reserv.SetTour(tour.title);
        await Reserv.SetDate(date);
        await Reserv.SetDepartureTime(time);
        await Reserv.SetPeople(tour.minimum_people);
        await Reserv.SetAdditionalPeople(people - tour.minimum_people);
        await Reserv.SetTotalCost((Reserv.additional_people * tour.price_additional_person)+Reserv.cost);
        setReservActive(true);
    }

    const increment = ()=> {
        if(people + 1 <= tour.maximum_people){
            setPeople(people + 1);
        };
    }

    const decrement = ()=> {
        if(people - 1 >= tour.minimum_people ){
            setPeople(people - 1);
        };
    }
    
    const changeName = (e)=> {
        const name = e.target.value;
        setName(name);
    }

    const changeLastName = (e)=> {
        const last_name = e.target.value;
        setLastName(last_name);
    }

    const changeEmail = (e)=> {
        const email = e.target.value.trim();
        if(!ValidateEmail(email)){
            setEerrorEmail(true);
        }else{
            setEerrorEmail(false);
        }
        setEmail(email);
    }

    const changePhone = (e)=> {
        const phone = e.target.value.trim();
        if(!ValidateNumbers(phone)){
            setEerrorPhone(true);
        }else{
            setEerrorPhone(false);
        }
        setPhone(phone);
    }

    const changeAdditionalComments = (e)=> {
        const additional_comments = e.target.value;
        setAdditionalComments(additional_comments);
    }

    const clearState = ()=>{
        setPeople(tour.minimum_people);
        setTime('');
        setReservActive(false);
        setName('');
        setLastName('');
        setEmail('');
        setEerrorEmail(true);
        setPhone('');
        setEerrorPhone(true);
        setAdditionalComments('');
    };

    const requestReservation = async ()=> {
        // if(!name){
        //     swal({
        //         text: content.alert_messages.write_your_name,
        //         icon: "warning" 
        //     });
        //     return;
        // };
        // if(!lastName){
        //     swal({
        //         text: content.alert_messages.write_your_lastname,
        //         icon: "warning" 
        //     });
        //     return;
        // };
        // if(!email){
        //     swal({
        //         text: content.alert_messages.write_an_email,
        //         icon: "warning" 
        //     });
        //     return;
        // };
        // if(!ValidateEmail(email)){
        //     swal({
        //         text: content.alert_messages.write_a_valid_email,
        //         icon: "warning" 
        //     });
        //     return;
        // };
        // if(!phone){
        //     swal({
        //         text: content.alert_messages.write_your_phone,
        //         icon: "warning" 
        //     });
        //     return;
        // };
        setLoading(true);
        await Reserv.SetName(name);
        await Reserv.SetLastName(lastName);
        await Reserv.SetEmail(email);
        await Reserv.SetPhone(phone);
        await Reserv.SetAdditionalComments(additionalComments);
        const data = {
            reservation: Reserv
        };
        const result = await callApi('send_request_reservation_email','post',data);
        setLoading(false);
        if(result.status === 200){
            swal({
                content: (
                    <div>
                        <Text className='mb-4' style={{fontWeight: 'bold', fontSize: '24px'}}>Solicitud de reservación enviada</Text>
                        <Text className='mb-4' style={{fontSize: '20px'}}>¡Enhorabuena! Estás a un paso de vivir una nueva aventura.</Text>
                        <Text style={{fontSize: '20px'}}>Hemos recibido tu solicitud de reservación correctamente. Nos pondremos en contacto contigo a la brevedad para afinar los últimos detalles de tu reservación y confirmar disponibilidad. Gracias por confiar en Baaxal adventure.</Text>
                    </div>
                ),
                icon: "success" 
            });
        }else{
            swal({
                text: content.alert_messages.your_information_can_not_send,
                icon: "warning" 
            });
        }
        clearState();
    }

    return (
        <>
            <TopMenuMobile location={location} />
            <TopMenu location={location} />
            <TourDetailWrapper className='mb-5'>
                <Container>
                    <Row className='mb-2'>
                        <Col>
                            <div className='d-flex align-items-center'>
                                <TitleDetailTourPost className='mr-4'>{tour.title}</TitleDetailTourPost>
                                <div>
                                    <Price>{tour.price} USD</Price>
                                    <DividerMicro />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mb-5'>
                        <Col lg='8' className='mb-2 mb-lg-0'>
                            <img className='img-fluid' src={tour.image_main_url} />
                        </Col>
                        <Col lg='4' className='d-flex flex-column justify-content-between'>
                            <img className='img-fluid mb-2' src={tour.image_large_url} />
                            <Row>
                                <Col><img className='img-fluid' src={tour.image_small_a} /></Col>
                                <Col><img className='img-fluid' src={tour.image_small_b} /></Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                {
                    !reservActive ? 
                        <Container className='info-post-wrapper mb-4'>
                            <Row className='mb-5'>
                                    <Col>
                                        <DescriptionWrapper className='mb-4'>{parse(tour.description)}</DescriptionWrapper>
                                        <TitleDescription className='mb-4'>{content.global.general_information}</TitleDescription>
                                        <Text>{`${content.global.duration}: ${tour.duration_time} ${content.global.hours}.`} </Text>
                                        <Text>{`${content.global.departure_point}: `}{parse(tour.departure_point)}</Text>
                                        {
                                            tour.arrival_point &&
                                                <Text>{`${content.global.arrival_point}: ${tour.arrival_point}.`}</Text>
                                        }
                                        <Text>{`${content.global.departure_time}: (${content.global.flexible} ${tour.departure_time}).`}</Text>
                                        <Text>{`${content.global.cost} (${tour.minimum_people} ${content.global.people}): $${tour.price} USD.`}</Text>
                                        <Text className={tour.fishing_odds ? null : 'mb-5'}>{`${content.global.cost_additional_person}: $${tour.price_additional_person} USD.`}</Text>
                                        {
                                            tour.fishing_odds &&
                                                <Text className='mb-5'>{`${content.global.fishing_odds}: ${tour.fishing_odds}`}</Text>
                                        }
                                        <hr className='mb-5' />
                                        <TitleDescription className='mb-4'>{content.global.what_includes}</TitleDescription>
                                        <Text>{`${content.global.the_service_includes}:`} {parse(tour.includes)}</Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormReserveWrapper className='p-2'>
                                            <TextWhite className='mb-1'>{content.global.select_your_reservation_details}</TextWhite>
                                            <Row>
                                                <Col className='mb-2 mb-lg-0' lg='3'>
                                                    <InfoInputWrapper className='d-flex align-items-center justify-content-between p-1'>
                                                        <Caption>{content.global.people}</Caption>
                                                        <CounterWrapper className='d-flex justify-content-between align-item-center'>
                                                            <BtnWrapper onClick={decrement}>
                                                                <Icon.IoMdRemoveCircleOutline color={Colors.gray} size={24} mode='outline' />
                                                            </BtnWrapper>
                                                            <Text>{people}</Text>
                                                            <BtnWrapper onClick={increment}>
                                                                <Icon.IoMdAddCircleOutline color={Colors.gray} size={24} mode='outline' />
                                                            </BtnWrapper>
                                                        </CounterWrapper>
                                                    </InfoInputWrapper>
                                                </Col>
                                                <Col className='mb-2 mb-lg-0' lg='3'>
                                                    <InfoInputWrapper className='d-flex justify-content-center'>
                                                        <InputDateTime 
                                                            type='date' 
                                                            value={date} 
                                                            min={minDate}
                                                            onChange={seDateTour}
                                                        />
                                                    </InfoInputWrapper>
                                                </Col>
                                                <Col className='mb-2 mb-lg-0' lg='3'>
                                                    <InfoInputWrapper className='d-flex justify-content-center'>
                                                        <SelectTime onChange={setTimeTour} value={time}>
                                                            <option>{content.global.departure_time}</option>
                                                            <option value='05:00'>05:00</option>
                                                            <option value='06:00'>06:00</option>
                                                            <option value='07:00'>07:00</option>
                                                            <option value='08:00'>08:00</option>
                                                            <option value='09:00'>09:00</option>
                                                            <option value='10:00'>10:00</option>
                                                            <option value='11:00'>11:00</option>
                                                            <option value='12:00'>12:00</option>
                                                            <option value='13:00'>13:00</option>
                                                            <option value='14:00'>14:00</option>
                                                            <option value='15:00'>15:00</option>
                                                            <option value='16:00'>16:00</option>
                                                            <option value='17:00'>17:00</option>
                                                            <option value='18:00'>18:00</option>
                                                            <option value='19:00'>19:00</option>
                                                            <option value='20:00'>20:00</option>
                                                        </SelectTime>
                                                    </InfoInputWrapper>
                                                </Col>
                                                <Col className='mb-2 mb-lg-0' lg='3'>
                                                    <Btn onClick={GoResumeReserv}>{content.global.continue}</Btn>
                                                </Col>
                                            </Row>
                                        </FormReserveWrapper>
                                    </Col>
                                </Row>
                        </Container>
                    :

                        <Container className='personal-data-wrapper'>
                            <Row>
                                <Col lg='8'>
                                    <TitleDescription className='mb-2'>{content.global.contact_information}</TitleDescription>
                                    <LineBlue className='mb-5' />
                                    <InputDataInformation label={content.global.name} required value={name} onChange={(e)=>{changeName(e)}} />
                                    <InputDataInformation label={content.global.last_name} required value={lastName} onChange={(e)=>{changeLastName(e)}} />
                                    <InputDataInformation label={content.global.email} required value={email} onChange={(e)=>{changeEmail(e)}} />
                                    <InputDataInformation label={content.global.phone} required value={phone} onChange={(e)=>{changePhone(e)}} />
                                    <TextAreaDataInformation label={content.global.additional_comments} value={additionalComments} onChange={(e)=>{changeAdditionalComments(e)}} ></TextAreaDataInformation>
                                </Col>
                                <Col lg='4' className='d-flex flex-column align-items-center'>
                                    <ResumeReservWrapper className='d-flex flex-column justify-content-between mb-3'>
                                        <SmallSubTitle style={{textAlign: 'center'}}>{content.global.order_summary}</SmallSubTitle>
                                        <Line />
                                        <SummaryContent>
                                            <Text>{Reserv.tour}</Text>
                                            <Text>{`${Reserv.people} ${content.global.people}`}</Text>
                                            <Text>{`${Reserv.additional_people} ${content.global.additional}`}</Text>
                                            <Text>{`${content.global.date} ${Reserv.date}`}</Text>
                                        </SummaryContent>
                                        <Line />
                                        <Text style={{textAlign: 'center', fontWeight: 'bold', textTransform: 'uppercase'}}>{`${content.global.total} $ ${Reserv.total_cost} USD`}</Text>
                                    </ResumeReservWrapper>
                                    <Btn 
                                        disabled={!name || !lastName || !email || !phone || errorEmail || errorPhone} 
                                        onClick={requestReservation}>{content.global.request_reservation}
                                    </Btn>
                                </Col>
                            </Row>
                        </Container>
                }
                </TourDetailWrapper>
            <Loading loading={loading} />
        </>
    )
}

const TourDetailWrapper = styled.section`

`;

const Price = styled.span`
    font-size: 28px;
    color: ${Colors.main_color};
    font-weight: bold;
    font-style: italic;
`;

const DescriptionWrapper = styled.div`
    
`;

const TitleDescription = styled.div`
    font-size: 16px;
    color: ${Colors.main_color};
`;

const FormReserveWrapper = styled.div`
    background-color: ${Colors.secondary_color};
`;
const InfoInputWrapper = styled.div`
    border-radius: 5px;
    height: 36px;
    background-color: ${Colors.white};
`;

const InputDateTime = styled.input`
    background: transparent;
    border: 0px;
`;

const SelectTime = styled.select`
    background: transparent;
    border: 0px;

    :focus {
        outline: none;
    }
`;

const Btn = styled.button`
    min-width: 156px;
    height: 36px;
    background-color: ${(props)=> props.disabled ? Colors.gray_light : Colors.main_color};
    color: ${Colors.white};
    border: 0px;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
`;

const CounterWrapper = styled.div`
    width: 100px;
`;

const BtnWrapper = styled.div`
    cursor: pointer;
`;

const ResumeReservWrapper = styled.div`
    border-radius: 38px;
    border: solid 1px ${Colors.main_color};
    height: 323px;
    width: 305px;
    padding: 16px;
`;

const SummaryContent = styled.div`
    text-align: center;
    text-transform: uppercase;
`;

export default TourDetail
