import React from 'react'
import styled from 'styled-components'
import { Colors } from '../theme/Colors';

const MainButton = ({text, onClick, idOffer, idPost}) => {
    return (
        <Button data-id-offer={idOffer} data-id-post={idPost} onClick={onClick}>{text}</Button>
    )
}

const Button = styled.button`
    background: ${Colors.main_color};
    border: 0px;
    border-radius: 10px;
    color: ${Colors.white};
    height: 60px;
    min-width: 184px;
    pointer-events: all;
`;

export default MainButton
