import React from 'react';
import styled from 'styled-components';
import BounceLoader from "react-spinners/BounceLoader";
import { Colors } from '../theme/Colors';

const Loading = ({loading}) => {
    return (
        <LoadingWrapper loading={loading}>
            <BounceLoader color={Colors.main_color} loading={loading} size={250} />
        </LoadingWrapper>
    )
}

const LoadingWrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.9);
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    display: ${(props)=> props.loading ? 'flex' : 'none'};
`;

export default Loading