import React from 'react'
import styled from 'styled-components'
import { Colors } from '../theme/Colors';

const SecondaryButton = ({text, onClick, white, style, uppercase}) => {
    return (
        <Button uppercase={uppercase} style={style} white={white} onClick={onClick}>{text}</Button>
    )
}

const Button = styled.button`
    background: transparent;
    border: solid 2px ${(props)=> props.white ? Colors.white : Colors.main_color};
    border-radius: 10px;
    color: ${(props)=> props.white ? Colors.white : Colors.main_color};
    height: 60px;
    min-width: 184px;
    text-transform: ${(props) => props.uppercase && 'uppercase'};
`;

export default SecondaryButton
