import React from 'react'
import styled from 'styled-components'
import { Colors } from '../theme/Colors';

const MainButtonWhite = ({text, onClick, uppercase}) => {
    return (
        <Button uppercase={uppercase} onClick={onClick}>{text}</Button>
    )
}

const Button = styled.button`
    background: ${Colors.white};
    border: 0px;
    border-radius: 10px;
    color: ${Colors.main_color};
    height: 60px;
    min-width: 184px;
    font-size: 16px;
    text-transform: ${(props) => props.uppercase && 'uppercase'};
`;

export default MainButtonWhite;
