import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { NavLink, Link } from 'react-router-dom';
import styled from 'styled-components';
import { Colors } from '../theme/Colors';
import MainIconButton from './MainIConButton';
import logo from '../images/logo.png';
import { content } from '../fixtures/Content';
import Ui from '../stores/Ui';
import gsap from 'gsap/gsap-core';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import {observer} from 'mobx-react';
import {useHistory} from 'react-router-dom';
import {Logo} from '../components/StyledComponents';

gsap.registerPlugin(ScrollToPlugin);

const TopMenu = observer((location) => {

    const {push} = useHistory();

    const ActiveStyle = {
        color: Colors.main_color
    };

    const Goto = (section)=> {
        switch (section) {
            
            case 'about':
                if(location){
                    if(location.pathname !== "/"){
                        push('/')
                    };
                }
                gsap.to(window, {scrollTo: '#about-wrapper'});
                break;

            case 'services':
                if(location){
                    if(location.pathname !== "/"){
                        push('/')
                    };
                }
                gsap.to(window, {scrollTo: '#services-wrapper'});
                break;
        
            default:
                break;
        }
    };

    const changeLang = async(e)=> {
        console.log(e.target.value);
        const newLang = e.target.value;
        content.setLanguage(Ui.lang);
        await Ui.SetLang(newLang);
    }

    const GoContact = ()=> {
        gsap.to(window, {scrollTo: '#footer-bottom-wrapper'});
    };

    return (
        <MenuWrapper className='menu-wrapper'>
            <Container className='d-none d-lg-block'>
                <MenuHolder className='menu-holder'>
                    <Container className='d-flex flex-column justify-content-center'>
                        <Row className='align-items-center'>
                            <Col md='1'>
                                <Link className='d-block' to='/'>
                                    <Logo src={logo} className='img-fluid' width={64} height={68} />
                                </Link>
                            </Col>
                            <Col md='7'>
                                <Menu className='menu'>
                                    <MenuItem>
                                        <Link to='/'>{content.top_menu.home}</Link>
                                    </MenuItem>
                                    <MenuItem>
                                        <Link onClick={()=>{Goto('about')}}>{content.top_menu.about_us}</Link>
                                    </MenuItem>
                                    <MenuItem>
                                        <Link onClick={()=>{Goto('services')}}>{content.top_menu.services}</Link>
                                    </MenuItem>
                                    <MenuItem>
                                        <Link to='/blog'>{content.top_menu.blog}</Link>
                                    </MenuItem>
                                </Menu>
                            </Col>
                            <Col md='2'>
                                <MainIconButton text={content.global.contact_us} onClick={GoContact} />
                            </Col>
                            <Col md='2'>
                                {/* <SelectLang value={Ui.lang} onChange={changeLang}>
                                    <option value='es'>Español</option>
                                    <option value='en'>English</option>
                                </SelectLang> */}
                            </Col>
                        </Row>
                    </Container>
                </MenuHolder>
            </Container>
        </MenuWrapper>
    )
})

const MenuWrapper = styled.div`
    margin-bottom: 150px;
    padding-top: 38px;
`;

const MenuHolder = styled.div`
    background: ${Colors.white};
    height: 80px;
    border-radius: 10px;
    display: flex;
    align-items: stretch;
`;

const SelectLang = styled.select`
    :focus{
        outline: none;
    }
`;

const Menu = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: end;
`;

const MenuItem = styled.li`
    color: ${Colors.black};
    display: inline-block;
    margin-right: 40px;

    a {
        color: ${Colors.black};
        text-decoration: none;   
    }

    a:visited{
        color: ${Colors.black};
    }

    a:hover{
        color: ${Colors.black};
        text-decoration: none;
    }
`;

export default TopMenu
