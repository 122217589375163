import React from 'react'
import styled from 'styled-components';
import {DividerMicro, Text, TextPriceItalic, TitleHeader} from '../components/StyledComponents.js';
import { BreakPoints } from '../fixtures/BreakPoints.js';
import { Colors } from '../theme/Colors.js';
import MainButton from './MainButton.js';
import parse from 'html-react-parser';
import { content } from '../fixtures/Content.js';

const CardPost = ({image, title, description, idPost, onClick, onMouseEnter, onMouseLeave}) => {
    return (
        <CardPostWrapper data-id-post={idPost} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className='card-offer-wrapper mb-2'>
            <ImgPostWrapper className='mb-3'>
                <ImgOffer className='mb-2 img-fluid' src={image} />
                <OverlayViewPost className={`post-${idPost}`}>
                    <MainButton idPost={idPost} onClick={onClick} text={content.home.view_more_details} />
                </OverlayViewPost>
            </ImgPostWrapper>
            <TitlePost className='mb-2'>{title}</TitlePost>
            <DividerMicro className='mb-3' />
            <DescriptionPostWrapper>{parse(description.substring(0,100))}</DescriptionPostWrapper>
        </CardPostWrapper>
    )
};

const CardPostWrapper = styled.div`

`;

const ImgPostWrapper = styled.div`
    max-width: 360px;
    max-height: 280px;
    pointer-events: none;
    position: relative;
    overflow: hidden;
`;

const TitlePost = styled.h2`
    font-size: 26px;
    color: ${Colors.black};
    pointer-events: none;

    @media (max-width: ${BreakPoints.lg}){
        font-size: 20px;
    }
`;

const ImgOffer = styled.img`
    width: 360px;
    height: 280px;
    border-radius: 10px;
    overflow: hidden;
    pointer-events: none;
    object-fit: cover;
`;

const DescriptionPostWrapper = styled.div`
    pointer-events: none;
`;


const OverlayViewPost = styled.div`
    background: rgba(255,255,255,0.8);
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
`;

export default CardPost
