export const config = {
    urlApi: 'https://us-central1-baaxal-ed7da.cloudfunctions.net/app/',
    firebaseConfig: {
        apiKey: "AIzaSyDtvo2FzjOLVtTFIneawAfOL5wQ6XfNF28",
        authDomain: "baaxal-ed7da.firebaseapp.com",
        projectId: "baaxal-ed7da",
        storageBucket: "baaxal-ed7da.appspot.com",
        messagingSenderId: "241746271061",
        appId: "1:241746271061:web:b6aeda4965be32072e6ddc",
        measurementId: "G-MS5XLTZD6H"
    }
};