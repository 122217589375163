export const Colors = {
    black: '#000',
    white: '#fff',
    main_color: '#1CADD0',
    secondary_color: '#0D6A90',
    secondary_color_dark: '#03334A',
    main_color_light: '#F85B80',
    gray: '#727272',
    gray_light: '#9A9A9A',
    bg_gray: '#F7F7F7',
};