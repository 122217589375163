import { config } from "./config";


const callApi = async(endPoint, method, data, contenType)=> {
    console.log(`${endPoint} data request =>`, data);
    try {
        let response = await fetch(`${config.urlApi}${endPoint}`, {
            method: method,
            headers: {
                'Origin': 'http://localhost:3000',
                'Accept': 'application/json',
                'Content-Type': contenType ? contenType : 'application/json',
            },
            body: data ? JSON.stringify(data) : null
        });
        console.log('response =>', response);
        let json = await response.json();
        console.log('response json call api =>', json);
        json.status = response.status;
        console.log(`${endPoint} =>`, json);
        return json;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export {callApi};