import React from 'react';
import styled from 'styled-components';
import {Text} from '../components/StyledComponents';
import { Colors } from '../theme/Colors';

const InputDataInformation = ({label, required, value, onChange, type}) => {
    return (
        <div className='mb-3'>
            {
                required ?
                    <Text>{`${label}*`}</Text>
                :
                    <Text>{`${label}`}</Text>
            }
            <Input type={type} value={value} onChange={onChange} />
        </div>
    )
}

const Input = styled.input`
    border: solid 1px ${Colors.black};
    height: 41px;
    width: 80%;
    border-radius: 5px;
`;

export default InputDataInformation
