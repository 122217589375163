import React from 'react'
import { DividerSmall, Text, Title } from './StyledComponents'

const ServiceItem = ({title, description}) => {
    return (
        <div>
            <Title className='mb-4'>{title}</Title>
            <DividerSmall className='mb-4' />
            <Text>{description}</Text>
        </div>
    )
}

export default ServiceItem
