import moment from 'moment';
import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import TopMenu from '../components/TopMenu';
import { content } from '../fixtures/Content';
import { Colors } from '../theme/Colors';
import parse from 'html-react-parser';
import { TitleDetailTourPost } from '../components/StyledComponents';
import TopMenuMobile from '../components/TopMenuMobile';

const PostDetail = ({location}) => {

    const [post] = useState(location.state.post);

    return (
        <>
            <TopMenuMobile location={location} />
            <TopMenu location={location} />
            <PostDetailWrapper className='post-detail-wrapper mb-5'>
                <Container>
                    <Row>
                        <Col>
                            <TitleDetailTourPost className='mb-3'>{post.title}</TitleDetailTourPost>
                            <Writer className='mb-4'>{`${content.post_detail.writer} ${post.writer}, ${moment.unix(post.created_at.seconds).format('DD MMM YYYY')}`}</Writer>
                            <ImagePost className='img-fluid mb-5' src={post.image_url} />
                            <ContentPost>{parse(post.description)}</ContentPost>
                        </Col>
                    </Row>
                </Container>
            </PostDetailWrapper>
        </>
    )
}

const PostDetailWrapper = styled.section`

`;

const Writer = styled.p`
    font-size: 14px;
    color: ${Colors.gray_light};
`;

const ImagePost = styled.img`
    object-fit: cover;
`;

const ContentPost = styled.div`

`;

export default PostDetail
