import moment from "moment";
import LocalizedStrings from "react-localization";

export const content = new LocalizedStrings({
    es: {
        global: {
            general_information: "Información general",
            duration: "Duración",
            hours: "horas",
            departure_point: "Punto de salida",
            arrival_point: "Punto de llegada",
            departure_time: "Hora de salida",
            cost: "Costo",
            people: "Personas",
            cost_additional_person: "Costo por persona adicional",
            flexible: "Flexible",
            what_includes: "Que incluye",
            the_service_includes: "El servicio incluye",
            fishing_odds: "Probabilidades de pesca",
            select_your_reservation_details: "Selecciona los detalles de tu reservación",
            contact_us: 'Contáctanos',
            continue: 'Continuar',
            order_summary: "Resumen de orden",
            additional: "adicional",
            date: "fecha",
            total: "total",
            request_reservation: "Solicitar reservación",
            contact_information: "Datos de contacto",
            name: 'Nombre',
            last_name: 'Apellidos',
            email: "Correo electrónico",
            phone: 'Teléfono',
            additional_comments: 'Comentarios adicionales',
            view_more: 'Ver más'
        },
        top_menu: {
            home: "Inicio",
            about_us: "Nosotros",
            services: "Servicios",
            blog: "Blog"
        },
        header: {
            header_subtitle: "TOURS DE SNÓRQUEL Y PESCA DEPORTIVA",
            header_title: "VIVE LA RIVERA MAYA",
        },
        home: {
            history_sea: "Nuestra historia en el mar",
            breeze_lanscapes: "La brisa, los paisajes y la libertad; todo eso nos encanta de nuestras aventuras diarias.",
            enjoy: "Disfrutamos de la fauna y flora de la Riviera Maya, y sobre todo de las nuevas experiencias.",
            want_share: "Queremos compartir contigo nuestras vivencias y crear nuevas historias, juntos. Por eso decidimos ofrecer nuestros tours de pesca y esnórquel. Tu viaje está por comenzar: deja que seamos tus guías en esta travesía inolvidable.",
            subscribe_newsletter: "Suscríbete a nuestro boletín para ofertas y promociones",
            subscribeme: "Suscribirme",
            services: "Servicios",
            find_experience: "Encuentra tu experiencia ideal, ¿Cuál será tu próxima aventura?",
            tours: "Tours",
            blog: "Blog",
            blog_subtitle: "Visita nuestro blog y adéntrate en el mundo de la pesca deportiva",
            view_more_details: "Ver más detalles"
        },
        blog_screen: {
            subtitle: "Adéntrate en el mundo de la pesca deportiva",
        },
        post_detail: {
            writer: "Escrito por",
        },
        footer: {
            contact: "Contacto",
            have_question: "¿Tienes preguntas o quieres una experiencia personalizada? No dudes en contactarnos y te responderemos a la brevedad.",
            contact_us: "Contáctanos",
            location: "Ubicación",
            copy_right: `${moment().format('YYYY')} Todos los derechos reservados`,
            state_address: "Quintana Roo,",
            city_address: "México Marina Puerto Aventuras",
            entity_address: "Solidaridad, 77733",
            tel: "+52 612 159 1853",
            email: "hello@baaxaladventure.com"
        },
        bottom_menu: {
            faqs: "Faqs",
            privacy_policy: "Aviso de privacidad",
            terms_conditions: "Términos y Condiciones"
        },
        alert_messages: {
            write_an_email: "Escribe tu correo electrónico.",
            write_a_valid_email: "Escribe un correo electrónico válido.",
            info_saved: "Tu información ha sido guardada con correctamente.",
            info_not_saved: "Tu información no ha sido guardada inténtalo más tarde.",
            select_departure_time: "Selecciona la hora de salida.",
            write_your_name: "Escribe tu nombre",
            write_your_lastname: "Escribe tus apellidos",
            write_your_phone: "Escribe tu teléfono",
            your_information_can_not_send: "Tu información no se pudo enviar, intenta más tarde."
        }
    },
    en: {
        top_menu: {
            home: "Home",
            about_us: "About",
            services: "Services",
            blog: "Blog"
        },
        header: {
            header_subtitle: "Snorkeling and sport fishing tours",
            header_title: "Enjoy the Riviera Maya",
        },
    }
});