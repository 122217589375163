import React from 'react'
import styled from 'styled-components'
import { Colors } from '../theme/Colors';

const MainIconButton = ({text, onClick, style}) => {
    return (
        <Button style={style} onClick={onClick}>
            {/* <IconHolder>
                <Icon.FaPhone color={Colors.white} size={18} />
            </IconHolder> */}
            {text}
        </Button>
    )
}

const Button = styled.button`
    background: ${Colors.main_color};
    border: 0px;
    border-radius: 10px;
    color: ${Colors.white};
    min-height: 45px;
    min-width: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const IconHolder = styled.div`
    margin-bottom: 4px;
`;

export default MainIconButton
