import React, { useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Colors } from '../theme/Colors';
import MainButtonWhite from './MainButtonWhite';
import { CaptionWhite, DividerMicroWhite, HeadingWhite, SubTitleItalicWhite, SubTitleWhite, TextWhite, TitleWhite, TitleWhiteHeader } from './StyledComponents';
import * as Icon from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { content } from '../fixtures/Content';
import Ui from '../stores/Ui';

const Footer = () => {

    return (
        <FooterWrapper>
            <FooterTopWrapper>
                <Container>
                    <Row className='align-items-center'>
                        <Col lg='6' className='mb-5 mb-lg-0'>
                            <HeadingWhite className='mb-4'>{content.footer.contact}</HeadingWhite>
                            <DividerMicroWhite className='mb-4' />
                            <SubTitleItalicWhite className='mb-4'>{content.footer.have_question}</SubTitleItalicWhite>
                            {/* <MainButtonWhite uppercase text={content.footer.contact_us} /> */}
                        </Col>
                        <Col lg='4' className='offset-lg-2'>
                            <TitleWhite className='mb-4'>{content.footer.location}</TitleWhite>
                            <DividerMicroWhite className='mb-4' />    
                            <TextWhite>{content.footer.state_address}</TextWhite>
                            <TextWhite>{content.footer.city_address}</TextWhite>
                            <TextWhite>{content.footer.entity_address}</TextWhite>
                            <div className='d-flex align-items-center'>
                                <Icon.FaPhone className='mr-2' color={Colors.white} size={16} />
                                <TextWhite>{content.footer.tel}</TextWhite>
                            </div>
                            <div className='d-flex align-items-center mb-4'>
                                <Icon.FaEnvelope className='mr-2' color={Colors.white} size={16} />
                                <TextWhite>{content.footer.email}</TextWhite>
                            </div>
                            <Container>
                                <Row>
                                    <Col xs={2}>
                                        <a href='https://www.instagram.com/baaxaladventure/' target='_blank'>
                                            <Icon.FaInstagram color={Colors.white} size={40} />
                                        </a>
                                    </Col>
                                    <Col xs={2}>
                                        <a href='https://www.facebook.com/baaxaladventure' target='_blank'>
                                            <Icon.FaFacebook color={Colors.white} size={40} />
                                        </a>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </FooterTopWrapper>
            <FooterBottomWrapper id='footer-bottom-wrapper'>
                <Container>
                    <Row className='align-items-center'>
                        <Col lg='3'>
                            <SubTitleWhite>Baaxal Adventure</SubTitleWhite>
                        </Col>
                        <Col lg='3'>
                            <CaptionWhite>{content.footer.copy_right}</CaptionWhite>
                        </Col>
                        <Col lg='6'>
                            {/* <BottomMenuWrapper>
                                <BottomMenuHolder>
                                    <BottomMenuItem>
                                        <NavLink to='/faqs'>{content.bottom_menu.faqs}</NavLink>
                                    </BottomMenuItem>
                                    <BottomMenuItem>
                                        <NavLink to='/helo'>{content.bottom_menu.privacy_policy}</NavLink>
                                    </BottomMenuItem>
                                    <BottomMenuItem>
                                        <NavLink to='/privacy'>{content.bottom_menu.terms_conditions}</NavLink>
                                    </BottomMenuItem>
                                </BottomMenuHolder>
                            </BottomMenuWrapper> */}
                        </Col>
                    </Row>
                </Container>
            </FooterBottomWrapper>
        </FooterWrapper>
    )
}

const FooterWrapper = styled.section`
    background: ${Colors.secondary_color};
    padding-top: 84px;
`;

const FooterTopWrapper = styled.div`
    padding-bottom: 170px;
`;

const FooterBottomWrapper = styled.div`
    background: ${Colors.secondary_color_dark};
    padding-bottom: 30px;
    padding-top: 30px;
`;

const BottomMenuWrapper = styled.div`

`;

const BottomMenuHolder = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

const BottomMenuItem = styled.li`
    display: inline-block;
    margin-right: 40px;
    color: ${Colors.white};

    a {
        color: ${Colors.white};
    }
    
    a:visited{
        color: ${Colors.white};
    }

    a:active{
        color: ${Colors.white};
    }

    a:hover{
        text-decoration: none;
    }
`;

export default Footer;
