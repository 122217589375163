import React from 'react';
import { Col, Container, Jumbotron, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { content } from '../fixtures/Content';
import ImgHeader from '../images/img_header.png';
import { Colors } from '../theme/Colors';
import MainButton from './MainButton';
import { HeadingWhiteHeader, TitleWhiteHeader } from './StyledComponents';
import TopMenu from './TopMenu';
import gsap from 'gsap/gsap-core';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import Ui from '../stores/Ui';
import TopMenuMobile from './TopMenuMobile';

gsap.registerPlugin(ScrollToPlugin);

const Header = () => {

    const ViewTours = ()=> {
        gsap.to(window, {scrollTo: '#special-offers-wrapper'});
    }

    return (
        <Jumbotron fluid>
            <TopMenuMobile />
            <HeaderWrapper>
                <TopMenu />
                <TextHeaderWrapper className='text-header-wrapper'>
                    <Container>
                        <Row>
                            <Col>
                                <TitleWhiteHeader style={{textAlign: 'center'}}>{content.header.header_subtitle}</TitleWhiteHeader>
                                <HeadingWhiteHeader style={{textAlign: 'center'}}>{content.header.header_title}</HeadingWhiteHeader>
                            </Col>
                        </Row>
                    </Container>
                </TextHeaderWrapper>
                <ButtonHeaderWrapper className='d-flex justify-content-center'>
                    <MainButton onClick={ViewTours} text='Ver Tours' />
                </ButtonHeaderWrapper>
            </HeaderWrapper>
        </Jumbotron>
    )

}

const HeaderWrapper = styled.div`
    background: url(${ImgHeader}) top center no-repeat;
    background-size: cover;
    min-height: 811px;
    //padding-top: 38px;
`;

const TextHeaderWrapper = styled.div`
    margin-bottom: 36px;
`;

const ButtonHeaderWrapper = styled.div`

`;
    
export default Header;
