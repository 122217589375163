import React from 'react';
import styled from 'styled-components';
import {Text} from '../components/StyledComponents';
import { Colors } from '../theme/Colors';

const TextAreaDataInformation = ({label, required, value, onChange,}) => {
    return (
        <div className='mb-3'>
            {
                required ?
                    <Text>{`${label}*`}</Text>
                :
                    <Text>{`${label}`}</Text>
            }
            <TextArea value={value} onChange={onChange}></TextArea>
        </div>
    )
}

const TextArea = styled.input`
    border: solid 1px ${Colors.black};
    border-radius: 5px;
    height: 107px;
    width: 80%;
    resize: none;
    align-content: flex-start;
    vertical-align: top;

    :focus {
        outline: none;
    }
`;

export default TextAreaDataInformation
