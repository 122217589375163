import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { firestore } from '../fixtures/firebase';
import TopMenu from '../components/TopMenu';
import { DividerSmall, Heading, SubTitleItalicGray } from '../components/StyledComponents';
import { content } from '../fixtures/Content';
import CardPost from '../components/CardPost';
import gsap from 'gsap/gsap-core';
import { useHistory } from 'react-router-dom';
import TopMenuMobile from '../components/TopMenuMobile';

const Blog = observer(({location}) => {

    const {push} = useHistory();
    
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const posts = [];
        const suscribePosts = firestore.collection('Posts').onSnapshot((querySnapShot)=>{
            querySnapShot.docs.forEach((doc)=>{
                let post = doc.data();
                post.id = doc.id;
                posts.push(post);
            });
            setPosts(posts);
        });
        return () => {
            suscribePosts();
        }
    }, []);

    const enterPost = (e)=> {
        const id_post = e.target.getAttribute('data-id-post')
        gsap.to(`.post-${id_post}`, {opacity: 1, top: 0, ease: 'expo'});
      }
    
      const outPost = (e)=> {
        const id_post = e.target.getAttribute('data-id-post')
        gsap.to(`.post-${id_post}`, {opacity: 0, top: '100%', ease: 'expo'});
      }
    
      const clickPost = (post)=> {
        push(`/post_detail/${post.title}`, {post});
      }

    return (
        <>
            <TopMenuMobile location={location} />
            <TopMenu location={location} />
            <BlogWrapper className='blog-wrapper mb-5'>
                <Container>
                    <DividerSmall className='mb-4' style={{margin: '0 auto'}} />
                    <Heading className='mb-3' style={{textAlign: 'center'}}>{content.home.blog}</Heading>
                    <SubTitleItalicGray className='mb-5' style={{textAlign: 'center'}}>{content.blog_screen.subtitle}</SubTitleItalicGray>
                    <Row className='mb-5'>
                        {
                        posts.map((item)=>
                            <Col lg='4' className='mb-5 mb-lg-0'>
                            <CardPost onClick={()=>{clickPost(item)}} onMouseEnter={enterPost} onMouseLeave={outPost} idPost={item.id} image={item.image_url} title={item.title} description={item.description} />
                            </Col>
                        )
                        }
                    </Row>
                </Container>
            </BlogWrapper>
        </>
    )
})

const BlogWrapper = styled.section`
    
`;

export default Blog;
