import { flow, types } from "mobx-state-tree";


const Ui = types.model('Ui', {
    offsetTopAbout: types.number,
    offsetTopServices: types.number,
    offsetTopTours: types.number,
    offsetTopBlog: types.number,
    offsetTopFooter: types.number,
    lang: types.string
}).actions((self)=>{
    const SetOffsetTops = flow(function *(offsetAbout, offsetServices, offsetTours, offsetBlog){
        self.offsetTopAbout = offsetAbout;
        self.offsetTopServices = offsetServices;
        self.offsetTopTours = offsetTours;
        self.offsetTopBlog = offsetBlog;
    });

    const SetOffsetTopFooter = flow(function *(offsetTop){
        self.offsetTopFooter = offsetTop;
    });

    const SetLang = flow(function *(newLang){
        self.lang = newLang;
    });

    return {
        SetOffsetTops,
        SetOffsetTopFooter,
        SetLang
    };
}).create({
    offsetTopAbout: 0,
    offsetTopServices: 0,
    offsetTopTours: 0,
    offsetTopBlog: 0,
    offsetTopFooter: 0,
    lang: 'es'
});

export default Ui;