import { Route, Switch } from "react-router";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { content } from "./fixtures/Content";
import Blog from "./screens/Blog";
import Home from "./screens/Home";
import PostDetail from "./screens/PostDetail";
import TourDetail from "./screens/TourDetail";
import Ui from "./stores/Ui";

function App() {

  content.setLanguage(Ui.lang);

  return (
    <>
      {/* <Header /> */}
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/blog' component={Blog} />
        <Route exact path='/post_detail/:post_name' component={PostDetail} />
        <Route exact path='/tour_detail/:tour_name' component={TourDetail} />
      </Switch>
      <Footer />
    </>
  );
}

export default App;
