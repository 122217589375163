import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import SecondaryButton from '../components/SecondaryButton';
import ServiceItem from '../components/ServiceItem';
import {DividerSmall, Heading, SubTitleItalicGray, Text, SubTitleItalicWhite} from '../components/StyledComponents';
import ImgAbout from '../images/img_about.png';
import { Colors } from '../theme/Colors';
import BgSpecialOffers from '../images/bg_special_offers.png';
import CardOffer from '../components/CardOffer';
import CardPost from '../components/CardPost';
import gsap from 'gsap';
import { content } from '../fixtures/Content';
import { firestore } from '../fixtures/firebase';
import { ValidateEmail } from '../fixtures/helpers';
import swal from 'sweetalert';
import Ui from '../stores/Ui';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import Header from '../components/Header';
import Loading from '../components/Loading';

const Home = observer(() => {

  const {push} = useHistory();
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [tours, setTours] = useState([]);
  const [posts, setPosts] = useState([]);
  const [emailNewsLetter, setEmailNewsLetter] = useState('');
  const [lang, setLang] = useState(Ui.lang);

  useEffect(() => {
    const suscribeServices = firestore.collection('Services').orderBy('created_at').limit(3).where('lang',"==",lang).onSnapshot((querySnapShot)=>{
      const services = [];
      querySnapShot.docs.forEach((doc)=>{
        let service = doc.data();
        service.id = doc.id;
        services.push(service);
      });
      setServices(services);
    });
    const suscribeTours = firestore.collection('Tours').orderBy('created_at').limit(4).onSnapshot((querySnapShot)=>{
      const tours = [];
      querySnapShot.docs.forEach((doc)=>{
        let tour = doc.data();
        tour.id = doc.id;
        tours.push(tour);
      });
      console.log(tours);
      setTours(tours);
    });
    const suscribeBlogLastItems = firestore.collection('Posts').orderBy('created_at').limit(3).onSnapshot((querySnapShot)=>{
      const posts = [];
      querySnapShot.docs.forEach((doc)=>{
        let post = doc.data();
        post.id = doc.id;
        posts.push(post);
      });
      setPosts(posts);
      setLoading(false);
    });
    
    return () => {
      suscribeServices();
      suscribeTours();
      suscribeBlogLastItems();
    }
  }, []);

  const enterOffer = (e)=> {
    const id_offer = e.target.getAttribute('data-id-offer')
    gsap.to(`.offer-${id_offer}`, {opacity: 1, top: 0, ease: 'expo'});
  }

  const outOffer = (e)=> {
    const id_offer = e.target.getAttribute('data-id-offer')
    gsap.to(`.offer-${id_offer}`, {opacity: 0, top: '100%', ease: 'expo'});
  }

  const clickOffer = (tour)=> {
    push(`/tour_detail/${tour.title}`, {tour});
  }

  const enterPost = (e)=> {
    const id_post = e.target.getAttribute('data-id-post')
    gsap.to(`.post-${id_post}`, {opacity: 1, top: 0, ease: 'expo'});
  }

  const outPost = (e)=> {
    const id_post = e.target.getAttribute('data-id-post')
    gsap.to(`.post-${id_post}`, {opacity: 0, top: '100%', ease: 'expo'});
  }

  const clickPost = (post)=> {
    push(`/post_detail/${post.title}`, {post});
  }

  const onChangeEmailNewsLetter = (e)=> {
    const email_newsletter = e.target.value.trim().toLowerCase();
    setEmailNewsLetter(email_newsletter);
  }

  const suscribeNewsletter = async()=> {
    if(!emailNewsLetter){
      swal({
        text: content.alert_messages.write_an_email,
        icon: "warning"
      });
      return;
    }
    if(!ValidateEmail(emailNewsLetter)){
      swal({
        text: content.alert_messages.write_a_valid_email,
        icon: "warning"
      });
      return;
    }
    setLoading(true);
    const result_add_newsletter = await firestore.collection('Newsletter').add({
      email: emailNewsLetter,
      created_at: new Date()
    });
    setLoading(false);
    if(result_add_newsletter.id){
      swal({
        text: content.alert_messages.info_saved,
        icon: "success"
      }).then(()=>{
        setEmailNewsLetter('');
      });
    }else{
      swal({
        text: content.alert_messages.info_not_saved,
        icon: "error"
      }).then(()=>{
        setEmailNewsLetter('');
      });
    }
  }

  return (
    <>
      <Header />
      <AboutWrapper id='about-wrapper' className='about-wrapper'>
        <Container>
          <Row>
            <Col lg='6' className='mb-5 mb-lg-0'>
              <Heading className='mb-3'>{content.home.history_sea}</Heading>
              <SubTitleItalicGray className='mb-4'>{content.home.breeze_lanscapes}</SubTitleItalicGray>
              <DividerSmall className='mb-4' />
              <Text className='mb-4'>{content.home.enjoy}</Text>
              <Text className='mb-4'>{content.home.want_share}</Text>
            </Col>
            <Col lg='6'>
              <ImageAbout className='img-fluid' src={ImgAbout} />
            </Col>
          </Row>
        </Container>
      </AboutWrapper>

      <NewsLetterWarpper className='newsletter-wrapper'>
        <Container>
          <SubTitleItalicWhite className='mb-4' style={{textAlign: 'center'}}>{content.home.subscribe_newsletter}</SubTitleItalicWhite>
          <Row className='justify-content-center'>
            <Col md='6' className='mb-4 mb-md-0'>
              <InputNewsletter placeholder='Email' onChange={onChangeEmailNewsLetter} value={emailNewsLetter} />
            </Col>
            <Col md='3' className='d-flex justify-content-center justify-content-md-start'>
              <SecondaryButton uppercase white text={content.home.subscribeme} onClick={suscribeNewsletter} />
            </Col>
          </Row>
        </Container>
      </NewsLetterWarpper>

      <ServicesWrapper id='services-wrapper' className='services-wrapper'>
        <Container>
          <DividerSmall className='mb-4' style={{margin: '0 auto'}} />
          <Heading uppercase className='mb-3' style={{textAlign: 'center'}}>{content.home.services}</Heading>
          <SubTitleItalicGray className='mb-5' style={{textAlign: 'center'}}>{content.home.find_experience}</SubTitleItalicGray>
          <Row>
            {
              services.map((service)=>{
                return(
                  <Col key={service.id} lg='4' className='mb-5 mb-lg-0'>
                    <ServiceItem title={service.title} description={service.description} />
                  </Col>
                )
              })
            }
          </Row>
        </Container>
      </ServicesWrapper>

      <SpecialOffersWrapper id='special-offers-wrapper' className='special-offers-wrapper'>
        <Container>
          <DividerSmall className='mb-4' style={{margin: '0 auto'}} />
          <Heading className='mb-5' style={{textAlign: 'center'}} uppercase>{content.home.tours}</Heading>
          <Row className='mb-5'>
            {
              tours.map((tour)=>
                <Col lg='6' className='mb-5 mb-lg-0'>
                  <CardOffer  
                    key={tour.id}
                    onClick={()=>{clickOffer(tour)}} 
                    onMouseEnter={enterOffer} 
                    onMouseLeave={outOffer} 
                    idOffer={tour.id} 
                    image={tour.image_main_url} 
                    title={tour.title}
                    price={tour.price} 
                    durationTime={tour.duration_time}
                    description={tour.description}
                  />
                </Col>
              )
            }
          </Row>
          {/* <SecondaryButton style={{margin: '0 auto', display: 'block'}} text='View more offers' /> */}
        </Container>
      </SpecialOffersWrapper>

      <BlogPostWrapper id='blog-wrapper' className='blog-wrapper'>
        <Container>
          <DividerSmall className='mb-4' style={{margin: '0 auto'}} />
          <Heading className='mb-3' style={{textAlign: 'center'}}>{content.home.blog}</Heading>
          <SubTitleItalicGray className='mb-5' style={{textAlign: 'center'}}>{content.home.blog_subtitle}</SubTitleItalicGray>
          <Row className='mb-5'>
            {
              posts.map((item)=>
                <Col lg='4' className='mb-5 mb-lg-0'>
                  <CardPost 
                    onClick={()=>{clickPost(item)}} 
                    onMouseEnter={enterPost} 
                    onMouseLeave={outPost} 
                    idPost={item.id} 
                    image={item.image_url} 
                    title={item.title} 
                    description={item.description} 
                  />
                </Col>
              )
            }
          </Row>
          <SecondaryButton style={{margin: '0 auto', display: 'block'}} text={content.global.view_more} onClick={()=>{push('/blog')}} />
        </Container>
      </BlogPostWrapper>
      <Loading loading={loading} />
    </>
  )
})

const AboutWrapper = styled.section`
  padding-bottom: 100px;
  padding-top: 100px;
`;

const ImageAbout = styled.img`
  margin: 0 auto;
  display: block;
`;

const NewsLetterWarpper = styled.section`
  background: ${Colors.secondary_color};
  padding-bottom: 70px;
  padding-top: 70px;
`;

const InputNewsletter = styled.input`
  height: 60px;
  width: 100%;
  background: ${Colors.main_color};
  border-radius: 10px;
  border: 0;
  padding-left: 15px;
  color: ${Colors.white};
  
  ::placeholder {
    color: ${Colors.white};
  }

  :focus {
      outline: none;
  }
`;

const ServicesWrapper = styled.section`
  background: ${Colors.bg_gray};
  padding-bottom: 80px;
  padding-top: 80px;
`;

const SpecialOffersWrapper = styled.section`
  background: url(${BgSpecialOffers}) top center no-repeat;
  background-size: cover;
  padding-bottom: 130px;
  padding-top: 130px;
`;

const BlogPostWrapper = styled.section`
  padding-bottom: 70px;
  padding-top: 70px;
`;

export default Home