import React from 'react'
import styled from 'styled-components';
import {DividerSmall, Text, TextPriceItalic, TitleHeader} from '../components/StyledComponents.js';
import { content } from '../fixtures/Content.js';
import MainButton from './MainButton.js';
import parse from 'html-react-parser';
import { Colors } from '../theme/Colors.js';

const CardOffer = ({image, title, durationTime, price, description, onMouseEnter, idOffer, onMouseLeave, onClick}) => {
    return (
        <CardOfferWrapper data-id-offer={idOffer} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className='card-offer-wrapper mb-2'>
            <ImgOfferWrapper className='img-offer-wrapper'>
                <ImgOffer className='mb-2 img-fluid' src={image} />
                <OverlayBuyOffer className={`offer-${idOffer}`}>
                    <MainButton idOffer={idOffer} onClick={onClick} text={content.home.view_more_details} />
                </OverlayBuyOffer>
            </ImgOfferWrapper>
            <TitleHeader className='mb-2'>{title}</TitleHeader>
            <DurationTimeWrapper className='mb-2'>
                <DurationTime>{`${content.global.duration} ${durationTime} ${content.global.hours} - `} <TextPriceItalic>{price} USD</TextPriceItalic></DurationTime>
            </DurationTimeWrapper>
            <DividerSmall className='mb-4' />
            <DescritionWrapper>{parse(description)}</DescritionWrapper>
        </CardOfferWrapper>
    )
};

const CardOfferWrapper = styled.div`
    
`;

const ImgOfferWrapper = styled.div`
    height: 320px;
    pointer-events: none;
    position: relative;
    overflow: hidden;
`;

const ImgOffer = styled.img`
    width: 555px;
    height: 320px;
    border-radius: 10px;
    overflow: hidden;
    pointer-events: none;
    object-fit: cover;
`;

const DurationTimeWrapper = styled.div`
    pointer-events: none;
`;

const DurationTime = styled.p`
    font-size: 16px;
    color: ${Colors.gray_light};
    font-style: italic;
    pointer-events: none;
`;

const DescritionWrapper = styled.div`
    pointer-events: none;
`;

const OverlayBuyOffer = styled.div`
    background: rgba(255,255,255,0.8);
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
`;

export default CardOffer;
